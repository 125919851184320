var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('div',{staticClass:"mx-7 pb-16"},[_c('div',{staticClass:"mb-5 mb-md-0"},[_c('div',{staticClass:"text-h3 font-weight-black"},[_vm._v(" Motivo de rechazo de solicitud ")])]),_c('v-row',{staticClass:"text-h4 align-center mt-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pb-1 pb-sm-0",attrs:{"cols":"12","sm":"5","md":"4"}},[_c('span',[_vm._v("Motivo de Rechazo")])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-select',{staticClass:"rounded-xl v-input--is-focused",attrs:{"items":_vm.searchSelect,"outlined":"","flat":"","dense":"","item-color":"secondary","single-line":"","required":"","hide-details":"auto","color":"secondary","background-color":"transparent","menu-props":{
              bottom: true,
              closeOnContentClick: true,
              allowOverflow: true,
              offsetY: true,
              rounded: 'md',
              transition: 'slide-y-transition',
            },"validate-on-blur":"","rules":_vm.fillInput},model:{value:(_vm.rejectMotive),callback:function ($$v) {_vm.rejectMotive=$$v},expression:"rejectMotive"}})],1)],1),_c('v-row',{staticClass:"mt-4 text-h4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pb-1 pb-sm-0",attrs:{"cols":"12","sm":"5","md":"4"}},[_c('span',[_vm._v("Comentario para el solicitante")])]),_c('v-col',{attrs:{"cols":"12","sm":"7","md":"6"}},[_c('v-textarea',{staticClass:"rounded-lg v-input--is-focused",attrs:{"solo":"","flat":"","dense":"","no-resize":"","outlined":"","hide-details":"auto","color":"secondary","auto-grow":"","background-color":"transparent","placeholder":"Escribir comentario","rows":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? 5 : 6,"maxlength":"255","counter":"255","rules":_vm.optionalTextAreaRules},model:{value:(_vm.rejectedComment),callback:function ($$v) {_vm.rejectedComment=$$v},expression:"rejectedComment"}})],1)],1)],1),_c('div',{staticClass:"mx-7 pb-16 d-flex justify-end flex-wrap"},[_c('v-spacer'),_c('Button',{staticClass:"mb-2",attrs:{"text":"Cancelar","outlined":"","disabled":_vm.loading,"horizontal-padding":6,"aria-label":"Cancelar"},on:{"event":function($event){return _vm.cancelRejectedRequest()}}}),_c('Button',{staticClass:"ml-2",attrs:{"text":"Enviar","disabled":_vm.loading,"type":"submit","aria-label":"Enviar"},on:{"event":function($event){return _vm.handleRejectRequest()}}})],1)]),_c('ModalLoading',{attrs:{"dialog":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }