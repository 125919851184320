<template>
  <div class="pb-16 mx-7">
    <div class="mb-5 text-h3 font-weight-black">
      Motivo de rechazo de solicitud
    </div>
    <v-row class="align-center mt-4 text-h4" no-gutters>
      <v-col cols="12" sm="5" md="4">
        <span>Motivo de rechazo</span>
      </v-col>
      <v-col cols="12" sm="7" md="8">
        <span>{{ rejectMotive }}</span>
      </v-col>
    </v-row>
    <v-row class="align-center mt-4 text-h4" no-gutters>
      <v-col cols="12" sm="5" md="4">
        <span>Comentario para el solicitante</span>
      </v-col>
      <v-col cols="12" sm="7" md="8">
        <span class="text-pre-line">
          {{
            providerComment
              ? providerComment
              : "No se han proporcionado comentarios."
          }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "RequesRejected",
  props: {
    rejectMotive: { type: String, required: true },
    providerComment: { type: String, default: "" },
  },
};
</script>

<style scoped></style>
