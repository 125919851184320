var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(
        _vm.buttonText === 'Adjuntar' ||
        _vm.buttonText === 'Subir contrato' ||
        _vm.buttonText === '+ Subir archivo (.PNG, .JPEG, .PDF)'
      )?_c('Button',{attrs:{"text":_vm.buttonText,"color":_vm.btnColor,"disabled":_vm.disabledFile,"loading":_vm.isSelecting,"aria-label":_vm.buttonText},on:{"event":function($event){return _vm.onButtonClick()}}}):(_vm.thereIsFiles !== '')?_c('v-btn',{staticClass:"rounded-pill btn-background-purple",attrs:{"outlined":"","disabled":_vm.disabledFile}},[_c('span',{staticClass:"text-none rounded-md font-weight-light text-h4",attrs:{"text":"","color":_vm.btnColor,"depressed":"","dark":"","ripple":false,"height":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? 45 : 49,"width":"100%","loading":_vm.isSelecting}},[_c('b',[_vm._v(" "+_vm._s(_vm.buttonText))]),(
            _vm.buttonText !== 'Adjuntar' ||
            _vm.buttonText !== 'Subir contrato' ||
            _vm.buttonText !== '+ Subir archivo (.PNG, .JPEG, .PDF)'
          )?_c('span',{staticClass:"flex-nowrap rounded-circle mb-10 ml-3 remove-file",attrs:{"align":"start","text":"","height":"40px","width":"40px"},on:{"click":_vm.onChangeStatus}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-close")])],1):_vm._e()])]):_vm._e()],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"accept":_vm.fileType,"type":"file"},on:{"change":_vm.onFileChanged}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }