<template>
  <div>
    <div class="purple-gradient fill-height">
      <Navbar page="provider" />
    </div>
    <div
      v-if="
        stripeInfoFetched &&
        !provider.stripeChargeEnable &&
        request.quotationRequest.status === 'pending'
      "
      class="header-container px-10 px-md-auto pb-md-0 py-6 py-md-14"
    >
      <div class="d-inline-block d-md-flex align-center pb-10">
        <v-container class="ml-md-6 ml-xl-14">
          <div class="text-h2 text-sm-h1 font-weight-bold mb-5">
            Registro pendiente de cuenta de Stripe
          </div>
          <div class="text-h4 mb-md-5">
            Para poder aceptar la solicitud debes de dar de alta tu cuenta de
            Stripe.
          </div>
        </v-container>
        <v-spacer></v-spacer>
        <div class="d-md-flex mt-10 mt-md-0 pr-lg-16">
          <Button
            text="Conectar mi cuenta"
            :disabled="loadingStripe"
            class="ml-3"
            aria-label="Conectar cuenta de Stripe"
            @event="redirectStripe()"
          />
        </div>
      </div>
    </div>
    <div
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? {
              'padding-top': '30px',
              'padding-bottom': '30px',
            }
          : {
              'padding-top': '60px',
              'padding-bottom': '40px',
            },
      ]"
    >
      <v-container
        v-if="request && !loading && stripeInfoFetched"
        class="px-10 px-md-auto"
      >
        <div class="text-h1 font-weight-black mb-2">Solicitud</div>
        <div class="d-flex flex-column flex-md-row mt-2 ml-1">
          <div class="text-h3 text-sm-1 mr-sm-10">
            No. de solicitud: {{ getId() }}
          </div>
        </div>
        <div class="d-flex flex-column flex-md-row ml-1">
          <div
            class="d-flex flex-column flex-sm-row text-h4 font-weight-regular mr-5 mb-3 mb-sm-0"
          >
            <div class="mr-1">Fecha de solicitud:</div>
            <div>
              {{ convertUnixToDate(requestDate) }}
            </div>
          </div>
          <div
            v-if="request.quotationRequest.status === 'approved'"
            class="d-flex flex-column flex-sm-row font-weight-regular"
          >
            <div class="mr-1 text-h4">Solicitud válida hasta:</div>
            <div class="text-h4">
              {{ convertUnixToDate(request.quotationRequest.validUntil) }}.
              Hasta las 23:59 (horario CST)
            </div>
          </div>
        </div>
        <Button
          text="Volver"
          outlined
          pill
          :height="43"
          prepend-icon="fa-caret-left"
          class="mt-9 mb-8"
          aria-label="Volver a lista de solicitudes"
          url="ProviderRequests"
        />
        <v-row>
          <v-col cols="12" md="6">
            <v-card
              elevation="0"
              class="mx-1 pa-7 text-h4 rounded-lg blue-border"
            >
              <div class="pb-1 text-h3 font-weight-black">
                Información del cliente
              </div>
              <div class="mt-4">
                {{ request.user.name }}
              </div>
              <div class="mt-4">
                {{ request.user.preferedName }}
              </div>
              <div class="mt-4">
                {{ request.quotationRequest.requesterCity }},
                {{ request.quotationRequest.requesterState }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card
              elevation="0"
              class="mx-1 pa-7 text-h4 rounded-lg blue-border"
            >
              <div class="pb-1 text-h3 font-weight-black">
                Información del proveedor
              </div>
              <div class="mt-4">
                <router-link
                  class="black--text text-decoration-underline"
                  :to="getProviderRoute"
                >
                  {{ request.provider.name }}
                </router-link>
              </div>
              <div class="mt-4">
                <router-link
                  class="black--text text-decoration-underline"
                  :to="getServiceRoute"
                >
                  {{ request.service.name }}
                </router-link>
              </div>
              <div class="mt-4">
                {{ providerAddress }}
              </div>
            </v-card>
          </v-col>
        </v-row>

        <div class="py-16">
          <div class="mx-7">
            <div
              v-if="request.quotationRequest.status == 'pending'"
              class="d-flex flex-column flex-md-row mb-5 mb-md-0"
            >
              <v-row>
                <v-col cols="12" md="6" class="order-0">
                  <div class="text-h3 font-weight-black">
                    Información de servicio solicitado
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="order-first order-md-1 d-flex justify-md-end pb-0"
                >
                  <v-radio-group v-model="requestResponseAction" mandatory row>
                    <div class="d-flex flex-column align-md-end">
                      <div class="d-flex mb-2 text-h4">
                        <v-radio value="accept" color="secondary">
                          <template v-slot:label>
                            <div class="black--text">Aceptar solicitud</div>
                          </template>
                        </v-radio>
                        <v-radio value="reject" color="secondary" class="mr-0">
                          <template v-slot:label>
                            <div class="black--text">Rechazar solicitud</div>
                          </template>
                        </v-radio>
                      </div>
                      <div
                        v-if="requestResponseAction == 'accept'"
                        class="pl-md-15 text-body-1 font-italic"
                      >
                        *Una vez que el usuario realice su primer pago, se
                        agendará en tu calendario.
                      </div>
                    </div>
                  </v-radio-group>
                </v-col>
              </v-row>
            </div>
            <div v-else class="mb-5 mb-md-0">
              <div class="text-h3 font-weight-black">Detalles de solicitud</div>
            </div>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Ciudad del evento</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span>
                  {{ request.quotationRequest.eventCity }},
                  {{ request.quotationRequest.eventState }}
                </span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Dirección</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span>{{ request.quotationRequest.eventAddress }}</span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Fecha del evento</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span>
                  {{ dateFormatter(request.quotationRequest.eventDate) }}
                </span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Hora</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span>
                  {{ hourFormatter(request.quotationRequest.eventDate) }}
                </span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Tipo de evento</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span>{{ request.quotationRequest.eventType }}</span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Número de invitados</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span>{{ request.quotationRequest.peopleQuantity }}</span>
              </v-col>
            </v-row>
            <v-row class="align-center mt-4 text-h4" no-gutters>
              <v-col cols="12" sm="5" md="4">
                <span>Comentarios</span>
              </v-col>
              <v-col cols="12" sm="7" md="8">
                <span class="text-pre-line">
                  {{
                    request.quotationRequest.clientComment
                      ? request.quotationRequest.clientComment
                      : "No se han proporcionado comentarios."
                  }}
                </span>
              </v-col>
            </v-row>
          </div>
        </div>

        <RequestApproved
          v-if="request.quotationRequest.status == 'approved'"
          :service-category="request.service.category"
          :payment-provider="request.quotationRequest.paymentProvider"
          :quotation-url="request.quotationRequest.quotationUrl"
          :request-extra-documents="
            request.quotationRequest.requestExtraDocument
          "
          :payment-advance="request.quotationRequest.paymentAdvance"
          :payment-deadline="request.quotationRequest.paymentDeadline"
          :service-event-date="request.quotationRequest.eventDate"
          :provider-comment="request.quotationRequest.providerComment"
        />
        <RequestRejected
          v-if="request.quotationRequest.status == 'rejected'"
          :reject-motive="request.quotationRequest.rejectedRequest.rejectMotive"
          :provider-comment="request.quotationRequest.providerComment"
        />
        <div v-if="request.quotationRequest.status == 'pending'">
          <RequestApprove
            v-if="requestResponseAction === 'accept'"
            :id="getId()"
            :payment-advance="request.quotationRequest.paymentAdvance"
          />
          <RequestReject
            v-else-if="requestResponseAction == 'reject'"
            :id="getId()"
          />
        </div>
      </v-container>
      <v-container
        v-if="!stripeInfoFetched"
        class="d-flex flex-column align-center my-16"
      >
        <v-progress-circular
          indeterminate
          color="secondary"
          :size="100"
          class="my-5 my-sm-16"
        ></v-progress-circular>
      </v-container>
    </div>
    <ModalLoading :dialog="loadingStripe" />
  </div>
</template>

<script>
import ModalLoading from "@/components/Shared/Modal/ModalLoading.vue";
import Button from "@/components/Shared/Button.vue";
import RequestApproved from "@/components/Provider/Request/RequestApproved.vue";
import RequestRejected from "@/components/Provider/Request/RequestRejected.vue";
import RequestApprove from "@/components/Provider/Request/RequestApprove.vue";
import RequestReject from "@/components/Provider/Request/RequestReject.vue";
import Navbar from "@/components/Provider/Shared/Navbar.vue";
import { GET_QUOTATION_DATA, GET_STRIPE_URL_REGISTER } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import { mapState } from "vuex";
import {
  convertUnixToDDMMYYYY,
  convertUnixToHourMinute,
  convertUnixToDate,
  convertDateToCstTimeZoneUnix,
} from "@/Utils/dateConverter";
import { formatProviderAddress } from "../../Utils/Utils";

export default {
  components: {
    ModalLoading,
    Button,
    RequestApproved,
    RequestRejected,
    RequestApprove,
    RequestReject,
    Navbar,
  },
  data: () => ({
    convertUnixToDDMMYYYY: convertUnixToDDMMYYYY,
    convertUnixToHourMinute: convertUnixToHourMinute,
    convertUnixToDate: convertUnixToDate,
    stripeInfoFetching: false,
    stripeInfoFetched: false,
    request: {
      quotationRequest: {
        status: "",
      },
      provider: {
        name: "",
      },
      service: {
        name: "",
      },
      user: {
        name: "",
      },
    },
    requestResponseAction: "accept",
    loading: true,
    loadingStripe: false,
  }),
  computed: {
    ...mapState({
      provider(state) {
        if (state.provider) {
          return state.provider;
        } else {
          return { photoUrl: null };
        }
      },
    }),
    providerAddress() {
      return formatProviderAddress(this.provider);
    },
    requestDate() {
      return convertDateToCstTimeZoneUnix(
        this.request.quotationRequest.insertedAt
      );
    },
    getProviderRoute() {
      return {
        name: "UserProvider",
        params: {
          id: this.request.provider.id,
        },
      };
    },
    getServiceRoute() {
      return {
        name: "UserService",
        params: {
          id: this.request.service.id,
        },
      };
    },
  },
  watch: {
    provider: {
      async handler(provider) {
        if (!provider.id) return;
        if (!this.stripeInfoFetching && !this.stripeInfoFetched) {
          this.stripeInfoFetching = true;
          await this.fetchStripeInfo();
          this.stripeInfoFetched = true;
          this.stripeInfoFetching = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async fetchQuotationRequest() {
      const props = {
        quotationId: parseInt(this.$route.params.id),
        clientId: parseInt(this.provider.id),
        route: "provider",
      };

      try {
        const { data, errors } = await useQuery(GET_QUOTATION_DATA, props);

        if (errors) throw errors;

        this.request = data.quotation;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$router.push({ name: "ProviderRequests" });
      }
    },
    async fetchStripeInfo(providerId) {
      await this.fetchQuotationRequest(providerId);
      if (
        (await providerId) == undefined ||
        this.request.quotationRequest.status !== "pending"
      )
        return;
      await this.$store.dispatch("retrieveProviderStripeInformation", {
        providerId: parseInt(providerId),
      });
    },
    getId() {
      return parseInt(this.$route.params.id);
    },
    dateFormatter(unixTime) {
      return convertUnixToDDMMYYYY(unixTime);
    },
    hourFormatter(unixTime) {
      return convertUnixToHourMinute(unixTime);
    },
    async redirectStripe() {
      this.loadingStripe = true;
      const { data, errors } = await useQuery(GET_STRIPE_URL_REGISTER, {
        stripe_id: this.provider.stripeConnectedAccount,
        return_url:
          process.env.VUE_APP_STRIPE_URL +
          "proveedor/solicitudes/" +
          this.$route.params.id,
        refresh_url: process.env.VUE_APP_STRIPE_URL + "proveedor/",
      });
      if (data) {
        window.location.replace(data.getStripeUrlRegister.url);
      } else if (errors) {
        this.handleAlert({
          type: "error",
          message: "Error durante la conexión con Stripe. Vuelve a intentarlo.",
        });
      }
      this.loadingStripe = false;
    },
  },
};
</script>

<style scoped>
::v-deep .v-select__selection,
::v-deep .v-select__selection--comma,
::v-deep .v-select.v-text-field input {
  color: black !important;
}
.v-data-table td {
  border-bottom: none !important;
}

tr:hover {
  background-color: transparent !important;
}

.v-data-table tr {
  vertical-align: 0;
}
.v-input--selection-controls {
  margin-top: 0;
  padding: 0;
}
.purple-gradient {
  background-color: rgba(98, 37, 130, 1);
}

.blue-border {
  border: 1px solid var(--v-secondary-base);
}
.purple-gradient {
  background-color: rgba(98, 37, 130, 1);
}
.header-container {
  background-color: rgba(98, 37, 130, 0.15);
}
</style>
