<template>
  <div>
    <v-form ref="form" v-model="isFormValid" lazy-validation @submit.prevent="">
      <div class="mx-7 pb-16">
        <div class="mb-5 mb-md-0">
          <div class="text-h3 font-weight-black">
            Motivo de rechazo de solicitud
          </div>
        </div>

        <v-row class="text-h4 align-center mt-4" no-gutters>
          <v-col cols="12" sm="5" md="4" class="pb-1 pb-sm-0">
            <span>Motivo de Rechazo</span>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-select
              v-model="rejectMotive"
              :items="searchSelect"
              outlined
              flat
              dense
              item-color="secondary"
              single-line
              required
              hide-details="auto"
              color="secondary"
              background-color="transparent"
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              validate-on-blur
              :rules="fillInput"
              class="rounded-xl v-input--is-focused"
            ></v-select>
          </v-col>
        </v-row>

        <v-row class="mt-4 text-h4" no-gutters>
          <v-col cols="12" sm="5" md="4" class="pb-1 pb-sm-0">
            <span>Comentario para el solicitante</span>
          </v-col>
          <v-col cols="12" sm="7" md="6">
            <v-textarea
              v-model="rejectedComment"
              solo
              flat
              dense
              no-resize
              outlined
              hide-details="auto"
              color="secondary"
              auto-grow
              class="rounded-lg v-input--is-focused"
              background-color="transparent"
              placeholder="Escribir comentario"
              :rows="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 5 : 6"
              maxlength="255"
              counter="255"
              :rules="optionalTextAreaRules"
            ></v-textarea>
          </v-col>
        </v-row>
      </div>
      <div class="mx-7 pb-16 d-flex justify-end flex-wrap">
        <v-spacer></v-spacer>
        <Button
          text="Cancelar"
          outlined
          :disabled="loading"
          :horizontal-padding="6"
          class="mb-2"
          aria-label="Cancelar"
          @event="cancelRejectedRequest()"
        />
        <Button
          text="Enviar"
          :disabled="loading"
          type="submit"
          class="ml-2"
          aria-label="Enviar"
          @event="handleRejectRequest()"
        />
      </div>
    </v-form>
    <ModalLoading :dialog="loading" />
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import ModalLoading from "@/components/Shared/Modal/ModalLoading.vue";
import { fillInput, optionalTextAreaRules } from "@/Utils/rules.js";
import { REJECT_QUOTATION_REQUEST } from "@/graphql/mutations";
import { useMutation } from "@/graphql/index";

export default {
  name: "RequestReject",
  components: { Button, ModalLoading },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    fillInput: fillInput,
    optionalTextAreaRules: optionalTextAreaRules,
    rejectMotive: "",
    rejectedComment: "",
    isFormValid: false,
    searchSelect: ["Precio", "Información proporcionada", "Otro"],
    loading: false,
  }),
  methods: {
    cancelRejectedRequest() {
      this.$router.push({ name: "ProviderRequests" });
    },
    async handleRejectRequest() {
      if (this.$refs.form.validate()) {
        this.loading = await true;
        const quotationId = parseInt(this.$route.params.id);
        const { data, errors } = await useMutation(REJECT_QUOTATION_REQUEST, {
          quotationRequestId: quotationId,
          rejectMotive: this.rejectMotive,
          providerComment: this.rejectedComment,
        });
        if (data) {
          await this.$store.dispatch("handleAlert", {
            type: "success",
            message: "La solicitud ha sido rechazada",
          });
          this.$router.push({ name: "ProviderRequests" });
        } else if (errors) {
          await this.$store.dispatch("handleAlert", {
            type: "error",
            message: "Error al rechazar solicitud",
          });
        }
      }
      this.loading = await false;
    },
  },
};
</script>
