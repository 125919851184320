<template>
  <v-row>
    <v-col cols="12">
      <Button
        v-if="
          buttonText === 'Adjuntar' ||
          buttonText === 'Subir contrato' ||
          buttonText === '+ Subir archivo (.PNG, .JPEG, .PDF)'
        "
        :text="buttonText"
        :color="btnColor"
        :disabled="disabledFile"
        :loading="isSelecting"
        :aria-label="buttonText"
        @event="onButtonClick()"
      />
      <v-btn
        v-else-if="thereIsFiles !== ''"
        outlined
        :disabled="disabledFile"
        class="rounded-pill btn-background-purple"
      >
        <span
          text
          :color="btnColor"
          depressed
          dark
          :ripple="false"
          :height="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 45 : 49"
          width="100%"
          class="text-none rounded-md font-weight-light text-h4"
          :loading="isSelecting"
          ><b> {{ buttonText }}</b>
          <span
            v-if="
              buttonText !== 'Adjuntar' ||
              buttonText !== 'Subir contrato' ||
              buttonText !== '+ Subir archivo (.PNG, .JPEG, .PDF)'
            "
            align="start"
            class="flex-nowrap rounded-circle mb-10 ml-3 remove-file"
            text
            height="40px"
            width="40px"
            @click="onChangeStatus"
          >
            <v-icon color="secondary">mdi-close</v-icon>
          </span>
        </span>
      </v-btn>
    </v-col>

    <input
      ref="uploader"
      :accept="fileType"
      class="d-none"
      type="file"
      @change="onFileChanged"
    />
  </v-row>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import { fileToBase64 } from "../../Utils/Utils";

export default {
  name: "FileUpload",
  components: {
    Button,
  },
  props: {
    fileType: {
      type: String,
      default: "",
    },
    btnColor: {
      type: String,
      default: "",
    },
    defaultButtonText: {
      type: String,
      default: "Adjuntar",
    },
    selectedFile: {
      type: String,
      default: "",
    },
    disabledFile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isSelecting: false,
    thereIsFiles: false,
  }),
  computed: {
    buttonText() {
      return this.thereIsFiles ? this.selectedFile : this.defaultButtonText;
    },
  },
  methods: {
    onChangeStatus() {
      this.thereIsFiles = false;
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    async onFileChanged(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Favor de subir un archivo valido");
      }
      let stringFile = await fileToBase64(files[0], false);
      this.$emit("uploadFile", {
        fileBase64: stringFile,
        filename: filename,
      });
      this.thereIsFiles = true;
    },
  },
};
</script>

<style scoped>
.remove-file {
  cursor: pointer;
}

.btn-background-transparent::before {
  background-color: transparent !important;
}

.btn-background-purple {
  background-color: rgba(98, 37, 130, 0.1) !important;
}

.btn-hover-purple:hover,
.btn-hover-purple:focus-within {
  background-color: rgba(98, 37, 130, 0.1) !important;
}
</style>
