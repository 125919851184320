<template>
  <div>
    <v-form ref="form" v-model="isFormValid" lazy-validation @submit.prevent="">
      <div class="mx-7 pb-16">
        <div class="mb-5 mb-md-0">
          <div class="text-h3 font-weight-black">Adjuntar cotización</div>
        </div>
        <v-row class="d-flex align-baseline mt-4 text-h4" no-gutters>
          <v-col cols="12" sm="5" md="4" class="pb-1 pb-sm-0">
            <span>Costo de servicio (IVA incluido)</span>
          </v-col>
          <v-col cols="8" sm="4" md="3">
            <v-text-field
              v-model="serviceCost"
              flat
              :disabled="!provider.stripeChargeEnable"
              dense
              solo
              outlined
              type="number"
              required
              pattern="^[0-9]+"
              min="0"
              step="1"
              hide-details="auto"
              color="secondary"
              class="rounded-lg v-input--is-focused"
              background-color="transparent"
              placeholder="0.00"
              validate-on-blur
              :rules="moneyRules"
              oninput="if(this.value < 0) this.value = '';"
              @input="handleQuantityFormat"
            >
              <template slot="prepend-inner">
                <span
                  class="mr-n1 black--text"
                  :class="!serviceCost && 'text--disabled'"
                >
                  $
                </span>
              </template>
            </v-text-field>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.sm" cols="5"></v-col>
          <v-col cols="12" sm="7" md="5" class="pl-md-4 pt-2 pl-sm-0">
            <div
              v-if="!minAdvanceCostUnreached && !maxAdvanceCostExceeded"
              class="descent--text"
            >
              Esta será la cifra que se mostrará al cliente.
            </div>
          </v-col>
        </v-row>
        <v-row class="align-center mt-4 text-h4" no-gutters>
          <v-col cols="12" sm="5" md="4">
            <span>Comisión Stripe ({{ stripeCommission | percent }})</span>
          </v-col>
          <v-col cols="12" sm="7" class="pl-3">
            <span>
              {{ numberToCurrency(stripeCommissionCost) }}
            </span>
          </v-col>
        </v-row>
        <v-row class="align-center mt-4 text-h4" no-gutters>
          <v-col cols="12" sm="5" md="4">
            <span>Comisión Zaturna {{ providerCommission | percent }}</span>
          </v-col>
          <v-col cols="12" sm="7" class="pl-3">
            <span>
              {{ numberToCurrency(providerCommissionCost) }}
            </span>
          </v-col>
        </v-row>
        <v-row class="mt-4 text-h4" no-gutters>
          <v-col cols="12" sm="5" md="4">
            <span class="font-weight-bold">Cantidad a recibir</span>
          </v-col>
          <v-col cols="12" sm="2" class="pl-3">
            <div class="font-weight-bold">
              {{ numberToCurrency(totalServiceCost) }}
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-4 text-h4" no-gutters>
          <v-col cols="12">
            <div :class="paymentAdvance !== 100 && 'mt-4'">
              <div v-if="minAdvanceCostUnreached" class="font-italic red--text">
                El costo del anticipo debe resultar en un monto mayor a $14.99.
                Consulta más detalles en
                <router-link
                  style="color: inherit"
                  :to="{ name: 'TermsOfServiceUser' }"
                  target="_blank"
                  >este enlace</router-link
                >.
              </div>
              <div
                v-else-if="maxAdvanceCostExceeded"
                class="font-italic red--text"
              >
                El costo del anticipo debe resultar en un monto menor a
                $1,000,000.00. Consulta más detalles en
                <router-link
                  style="color: inherit"
                  :to="{ name: 'TermsOfServiceUser' }"
                  target="_blank"
                  >este enlace</router-link
                >.
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-12 text-h4" no-gutters>
          <v-col cols="12" sm="5" md="4" class="pr-8 pb-3 pb-sm-0">
            <span>
              Puedes adjuntar tu archivo de cotización con el desglose de lo que
              incluye tu servicio.
            </span>
          </v-col>
          <v-col cols="12" sm="7" md="8">
            <File-upload
              :btn-color="btnColor"
              :selected-file="quotationText"
              :disabled-file="!provider.stripeChargeEnable"
              @uploadFile="getQuotation"
            />
            <div
              v-if="noFile == true"
              style="color: red; font-size: 12px"
              class="pt-2 pl-4"
            >
              Favor de subir un archivo
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-12 text-h4" no-gutters>
          <v-col cols="12" sm="5" md="4" class="pr-8 pb-3 pb-sm-0">
            <span>
              Puedes adjuntar archivos extra que puedan ser de ayuda para tu
              cliente.
            </span>
          </v-col>
          <v-col cols="12" sm="7" md="8">
            <File-upload
              :btn-color="btnColor"
              :disabled-file="!provider.stripeChargeEnable"
              :selected-file="extraText"
              @uploadFile="getExtraDoc"
            />
          </v-col>
        </v-row>
      </div>
      <div class="pb-10 mx-7">
        <div class="mb-5 text-h3 font-weight-black">Validez de cotización</div>
        <v-row class="mt-4" no-gutters>
          <v-col cols="12" sm="5" md="4" class="pr-8 pb-2 pb-sm-0 text-h4">
            <span>
              El cliente podrá aceptar tu cotización hasta la fecha
              seleccionada.
            </span>
          </v-col>
          <v-col cols="7" sm="4" md="3">
            <v-menu
              v-model="date.modelMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormatted"
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  class="rounded-lg v-input--is-focused"
                  type="text"
                  append-icon="mdi-calendar-month-outline secondary--text"
                  placeholder="DD/MM/AAAA"
                  hide-details="auto"
                  :rules="date.rules"
                  :disabled="!provider.stripeChargeEnable"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date.model"
                color="secondary"
                no-title
                required
                prev-icon="fa-chevron-left secondary--text"
                next-icon="fa-chevron-right secondary--text"
                show-adjacent-months
                :min="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
                @input="date.modelMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <div class="pb-16 mx-7">
        <v-row no-gutters>
          <v-col cols="12">
            <div class="mb-5 text-h3 font-weight-black">Comentarios</div>
          </v-col>
          <v-col cols="12" md="7">
            <v-textarea
              v-model="providerComment"
              :disabled="!provider.stripeChargeEnable"
              solo
              flat
              dense
              no-resize
              outlined
              hide-details="auto"
              color="secondary"
              auto-grow
              class="rounded-lg v-input--is-focused"
              background-color="transparent"
              placeholder="Escribir comentario"
              :rows="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 4 : 6"
              maxlength="255"
              counter="255"
              :rules="optionalTextAreaRules"
            ></v-textarea>
          </v-col>
        </v-row>
      </div>
      <div class="mx-7 pb-16 d-flex justify-end flex-wrap">
        <v-spacer></v-spacer>
        <Button
          text="Cancelar"
          outlined
          :disabled="loading"
          :horizontal-padding="6"
          class="mb-2"
          aria-label="Cancelar"
          @event="handleCancel()"
        />
        <Button
          text="Enviar"
          :disabled="
            loading ||
            !provider.stripeChargeEnable ||
            minAdvanceCostUnreached ||
            maxAdvanceCostExceeded
          "
          type="submit"
          class="ml-2"
          aria-label="Enviar"
          @event="handleAcceptRequest()"
        />
      </div>
    </v-form>
    <ModalLoading :dialog="loading" />
  </div>
</template>

<script>
import FileUpload from "@/components/Shared/FileUpload.vue";
import Button from "@/components/Shared/Button.vue";
import ModalLoading from "@/components/Shared/Modal/ModalLoading.vue";
import { moneyRules, fillInput, optionalTextAreaRules } from "@/Utils/rules.js";
import { ACCEPT_QUOTATION_REQUEST } from "@/graphql/mutations";
import { useMutation } from "@/graphql/index";
import {
  convertYYYYMMDDToUnixAtMidnight,
  convertYYYYMMDDToDDMMYYYY,
} from "@/Utils/dateConverter.js";
import { numberToCurrency } from "@/Utils/numberFormatter.js";
import { mapState } from "vuex";

export default {
  components: { FileUpload, Button, ModalLoading },
  filters: {
    percent(dec) {
      if (dec <= 1) {
        return dec * 100 + "%";
      } else if (dec <= 100) {
        return dec + "%";
      }
    },
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    paymentAdvance: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    extraText: "Archivo",
    quotationText: "Cotización",
    date: {
      text: "Fecha del evento",
      modelMenu: false,
      model: "",
      rules: fillInput,
    },
    loading: false,
    noFile: false,
    moneyRules: moneyRules,
    optionalTextAreaRules: optionalTextAreaRules,
    paymentZaturna: 0,
    providerComment: "",
    stripeInfoFetching: false,
    stripeInfoFetched: false,
    totalPayment: 0,
    validUntil: "",
    quotation: {
      base64: "",
      name: "",
    },
    extraDocuments: [],
    serviceCost: "",
    providerCommission: 0.0,
    clientCommission: 0.05,
    stripeCommission: 0.042,
    btnColor: "secondary",
    fileType: ".pdf,.doc",
    isFormValid: false,
    minAdvanceCostUnreached: false,
    maxAdvanceCostExceeded: false,
  }),
  computed: mapState({
    provider(state) {
      if (state.provider) {
        return state.provider;
      } else {
        return { photoUrl: null };
      }
    },
    stripeCommissionCost: function () {
      return parseFloat(this.serviceCost * this.stripeCommission).toFixed(2);
    },
    clientCommissionCost: function () {
      return parseFloat(this.serviceCost * this.clientCommission).toFixed(2);
    },
    providerCommissionCost: function () {
      return parseFloat(this.serviceCost * this.providerCommission).toFixed(2);
    },
    totalServiceCost: function () {
      if (this.serviceCost.length >= 1) {
        return parseFloat(
          this.serviceCost -
            this.serviceCost * (this.providerCommission + this.stripeCommission)
        ).toFixed(2);
      } else {
        return "0.00";
      }
    },
    totalPaymentAdvance: function () {
      if (this.serviceCost.length >= 1) {
        const clientTotalCost =
          parseFloat(this.serviceCost) * (1 + this.clientCommission);
        return clientTotalCost * (this.paymentAdvance / 100.0);
      } else {
        return "0.00";
      }
    },
    computedDateFormatted() {
      return convertYYYYMMDDToDDMMYYYY(this.date.model);
    },
  }),
  watch: {
    provider: {
      async handler(provider) {
        if (!provider.id) return;
        if (!this.stripeInfoFetching && !this.stripeInfoFetched) {
          this.stripeInfoFetching = true;
          await this.fetchStripeInfo(provider.id);
          this.stripeInfoFetched = true;
          this.stripeInfoFetching = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async fetchStripeInfo(providerId) {
      if ((await providerId) == undefined) return;
      await this.$store.dispatch("retrieveProviderStripeInformation", {
        providerId: parseInt(providerId),
      });
    },
    numberToCurrency(number) {
      return numberToCurrency(number);
    },
    async handleQuantityFormat() {
      if (this.serviceCost) {
        await this.$nextTick();
        this.serviceCost = this.serviceCost.replace(
          /^(?:0+(?=[1-9])|0+(?=0$))/gm,
          ""
        );
        this.serviceCost = this.serviceCost.replace(/(?<=\.[0-9]{2}).+/g, "");

        this.handleMinAdvanceCost();
        this.handleMaxAdvanceCost();
      } else {
        this.minAdvanceCostUnreached = false;
        this.maxAdvanceCostExceeded = false;
      }
    },
    handleMinAdvanceCost() {
      this.minAdvanceCostUnreached = this.totalPaymentAdvance.toFixed(2) < 15.0;
    },
    handleMaxAdvanceCost() {
      this.maxAdvanceCostExceeded =
        this.totalPaymentAdvance.toFixed(2) > 999999.99;
    },
    handleCancel() {
      this.$router.push({ name: "ProviderRequests" });
    },
    totalCost() {
      return parseFloat(
        this.serviceCost * this.clientCommission + parseFloat(this.serviceCost)
      ).toFixed(2);
    },
    getExtraDoc(context) {
      this.extraDocuments = [];
      this.extraDocuments.push({
        base64: context.fileBase64,
        name: context.filename,
      });
    },
    getQuotation(context) {
      this.quotation.base64 = context.fileBase64;
      this.quotation.name = context.filename;
      this.noFile == true ? (this.noFile = false) : (this.noFile = false);
    },
    async handleAcceptRequest() {
      if (this.quotation.name != "") {
        this.noFile = false;
      } else {
        this.noFile = true;
      }
      if (
        this.$refs.form.validate() &&
        !this.minAdvanceCostUnreached &&
        !this.maxAdvanceCostExceeded
      ) {
        this.loading = await true;
        if (this.noFile == true) {
          this.loading = await false;
          return;
        }
        const props = {
          quotationRequestId: this.id,
          paymentProvider: parseFloat(this.serviceCost),
          paymentZaturna: 0,
          providerComment: this.providerComment,
          totalPayment: 0,
          validUntil: String(convertYYYYMMDDToUnixAtMidnight(this.date.model)),
          quotation: {
            base64: this.quotation.base64,
            name: this.quotation.name,
          },
          extraDocuments:
            this.extraDocuments.length != 0
              ? [
                  {
                    base64: this.extraDocuments[0].base64,
                    name: this.extraDocuments[0].name,
                  },
                ]
              : [],
        };
        props.paymentZaturna = parseFloat(this.clientCommissionCost);
        props.totalPayment = parseFloat(this.totalCost());
        const { data, errors } = await useMutation(
          ACCEPT_QUOTATION_REQUEST,
          props
        );

        if (data) {
          await this.$store.dispatch("handleAlert", {
            type: "success",
            message: "La solicitud ha sido aceptada",
          });
          this.$router.push({ name: "ProviderRequests" });
        } else if (errors) {
          await this.$store.dispatch("handleAlert", {
            type: "error",
            message: "Error al aceptar solicitud",
          });
        }
      }
      this.loading = await false;
    },
  },
};
</script>

<style scoped>
.v-data-table td {
  border-bottom: none !important;
}
tr:hover {
  background-color: transparent !important;
}
.v-application--wrap {
  min-height: 0;
}
</style>
