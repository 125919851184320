<template>
  <div>
    <div class="pb-16 mx-7">
      <div class="mb-5 text-h3 font-weight-black">
        Desglose de servicio solicitado
      </div>
      <v-row class="align-center mt-4 text-h4" no-gutters>
        <v-col cols="12" sm="5" md="4">
          <span>Tipo de servicio</span>
        </v-col>
        <v-col cols="12" sm="7" md="8">
          <span>{{ serviceCategory }}</span>
        </v-col>
      </v-row>
      <v-row class="align-center mt-4 text-h4" no-gutters>
        <v-col cols="12" sm="5" md="4">
          <span>Costo de servicio (IVA incluido)</span>
        </v-col>
        <v-col cols="12" sm="7" md="8">
          <span>
            {{ numberToCurrency(paymentProvider) }}
            MXN
          </span>
        </v-col>
      </v-row>
      <v-row class="align-center mt-4 text-h4" no-gutters>
        <v-col cols="12" sm="5" md="4">
          <span>Comisión Stripe (4.2%)</span>
        </v-col>
        <v-col cols="12" sm="7" md="8">
          <span>
            {{ numberToCurrency(paymentProvider * 0.042) }}
            MXN
          </span>
        </v-col>
      </v-row>
      <v-row class="align-center mt-4 text-h4" no-gutters>
        <v-col cols="12" sm="5" md="4">
          <span>Comisión Zaturna 0%</span>
        </v-col>
        <v-col cols="12" sm="7" md="8">
          <span>
            {{ numberToCurrency(paymentProvider * 0.0) }}
            MXN
          </span>
        </v-col>
      </v-row>
      <v-row class="align-center mt-4 text-h4" no-gutters>
        <v-col cols="12" sm="5" md="4">
          <span class="font-weight-bold">Cantidad a recibir</span>
        </v-col>
        <v-col cols="12" sm="7" md="8">
          <span class="font-weight-bold">
            {{
              numberToCurrency(
                paymentProvider -
                  paymentProvider * (stripeCommission + providerCommission)
              )
            }}
            MXN
          </span>
        </v-col>
      </v-row>
      <v-row class="align-center mt-4 text-h4" no-gutters>
        <v-col cols="12" sm="5" md="4">
          <span>Cotización de servicio</span>
        </v-col>
        <v-col cols="12" sm="7" md="8">
          <Button
            text="Ver"
            class="my-2 my-md-4"
            aria-label="Descargar cotización de servicio"
            is-external
            download
            :url="quotationUrl"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="requestExtraDocuments.length > 0"
        class="align-center mt-4 text-h4"
        no-gutters
      >
        <v-col cols="12" sm="5" md="4">
          <span>Archivo adicional</span>
        </v-col>
        <v-col cols="12" sm="7" md="8">
          <a
            class="text-decoration-underline font-weight-bold"
            style="color: inherit"
            :href="requestExtraDocuments[0].url"
            download
          >
            Archivo
          </a>
        </v-col>
      </v-row>
      <v-row class="align-center mt-4 text-h4" no-gutters>
        <v-col cols="12" sm="5" md="4">
          <span>Comentarios del proveedor</span>
        </v-col>
        <v-col cols="12" sm="7" md="8">
          <span class="text-pre-line">
            {{
              providerComment
                ? providerComment
                : "No se han proporcionado comentarios."
            }}
          </span>
        </v-col>
      </v-row>
    </div>
    <div class="pb-9 mx-7">
      <div class="mb-5 text-h3 font-weight-black">Plan de pagos</div>
      <v-row class="align-center mt-4 text-h4" no-gutters>
        <v-col cols="12" sm="5" md="4">
          <span>
            Anticipo
            {{ paymentAdvance }}%
          </span>
        </v-col>
      </v-row>
      <v-row class="align-center mt-4 text-h4" no-gutters>
        <v-col cols="12" sm="5" md="4">
          <span>
            Liquidación
            <span v-if="dateDifference(serviceEventDate, paymentDeadline) == 0">
              el día del evento
            </span>
            <span v-else>
              {{ dateDifference(serviceEventDate, paymentDeadline) }}
              {{
                dateDifference(serviceEventDate, paymentDeadline) == 1
                  ? "día"
                  : "días"
              }}
              antes del evento
            </span>
          </span>
        </v-col>
      </v-row>
      <v-row class="align-center mt-4 text-h4" no-gutters>
        <span class="font-italic">
          Proveedor acepta
          <a class="text-decoration-underline" style="color: inherit">
            Política de Cancelación
          </a>
        </span>
      </v-row>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import { numberToCurrency } from "@/Utils/numberFormatter.js";
import { dateDifference } from "@/Utils/dateConverter.js";

export default {
  name: "RequestApproved",
  components: { Button },
  props: {
    serviceCategory: { type: String, required: true },
    paymentProvider: { type: Number, required: true },
    quotationUrl: { type: String, required: true },
    requestExtraDocuments: { type: Array, required: true },
    paymentAdvance: { type: Number, required: true },
    paymentDeadline: { type: String, required: true },
    serviceEventDate: { type: String, required: true },
    providerComment: { type: String, default: "" },
  },
  data: () => ({
    stripeCommission: 0.042,
    providerCommission: 0.0,
  }),
  methods: {
    numberToCurrency(number) {
      return numberToCurrency(number);
    },
    dateDifference(unixTimeOne, unixTimeTwo) {
      return dateDifference(unixTimeOne, unixTimeTwo);
    },
  },
};
</script>

<style scoped></style>
